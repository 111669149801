.ag-root-wrapper {
  border: none;
}

.b-editable-cell {
  border: 1px solid lightGray !important;
  background-color: #ecf2fa;
}

.b-changed-cell {
  color: red;
}

.b-error-cell {
  background-color: #b7bdc7;
}
.ag-header-cell-text {
  font-size: 14px;
}

/* 一覧（ag-grid）のフォントにMeiryo UIを優先 */
/* alpineデザインしか利用していないため、こちらのみ設定 */
.ag-theme-alpine {
  --ag-font-family: "M PLUS 2", -apple-system, BlinkMacSystemFont, "Meiryo UI",
    Meiryo, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 削除行 */
.b-ag-deleted-row {
  --ag-selected-row-background-color: silver;
}

/* 一覧(ag-grid)のヘッダ行の背景色(白) */
.b-header-row-white .ag-header-row {
  background-color: white !important;
}

/* 一覧(ag-grid)のヘッダ行のセンタリング */
.b-header-cell-center .ag-header-cell-label {
  display: flex;
  justify-content: center;
}

/* 一覧(ag-grid)のセル毎の右罫線(灰色) */
.b-cell-border-right-solid-thin-gray {
  border-right-style: solid !important;
  border-right-width: thin !important;
  border-right-color: #babfc7 !important;
}

/* TODO:Ph2で要らなくなりそうだが現状まだ使っているため残しておく */
/* 一覧(ag-grid)のセルの背景色薄い青(合計列に使用) */
.b-cell-backgroupd-color-light-blue {
  background-color: #ccccfe;
}

/* 一覧(ag-grid)のセルの背景色青(合計列に使用) コスト推移画面*/
.b-cell-backgroupd-color-blue {
  background-color: #ebf2fe;
}

/* 一覧(ag-grid)のセルの背景色(合計列に使用) */
.b-cell-backgroupd-color-gray {
  background-color: #e4e4e4;
}

/* TODO:Ph2にて使用する可能性があるため残しておく */
/* 一覧(ag-grid)のBEMAC回答列 */
.b-ag-column-reply {
  background-color: "";
}
/* 一覧(ag-grid)のキャンセル列 */
.b-ag-column-cancel {
  background-color: "";
}
/* ここまで */

/* 一覧(ag-grid)の列hover時 */
.ag-row-hover {
  --ag-row-hover-color: #f5f5f5;
}
