.custom-splide-container {
  position: relative;
  padding: 10px 50px;
}

.splide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.splide__arrow--prev {
  left: -50px; /* 左矢印ボタンの位置を調整 */
}

.splide__arrow--next {
  right: -50px; /* 右矢印ボタンの位置を調整 */
}
