@import url("https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 追加 */
:root {
  --key-color: #0d1532;
  --week-border-color: #d1d1d1;
}

/* 一覧画面背景 */
.b-container-list {
  background-color: #e4e4e4;
  min-height: calc(100vh - 50px);
}

/* 詳細画面背景 */
.b-modal-detail {
  background-color: #bfbfbf;
}

/* input活性 */
input[type="text"]:enabled,
input[type="number"]:enabled,
input[type="date"]:enabled,
input[type="password"]:enabled,
input[type="email"]:enabled,
.textarea:enabled {
  background-color: #f5f5f5;
}

/* input等非活性 */
input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="date"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
.textarea:disabled,
.disabled {
  background-color: #b7bdc7 !important;
}

/* 項目非活性時の罫線 */
.b-input-group-text:has(+ input.form-control:disabled, + textarea.form-control:disabled, + .disabled),
.form-control:disabled,
.disabled {
  border: solid;
  border-width: 1px;
  border-color: #595959;
}

/* input[type="number"]非活性時、未フォーカス時スピンボタンを削除 */
input[type="number"]:disabled::-webkit-outer-spin-button,
input[type="number"]:not(:focus)::-webkit-outer-spin-button,
input[type="number"]:disabled::-webkit-inner-spin-button,
input[type="number"]:not(:focus)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* グリッドの改行するセル */
.b-grid-cell-multi-line {
  white-space: normal !important;
  word-wrap: break-word !important;
  line-height: 1.2 !important;
  display: flex !important;
  align-items: center; /* 垂直方向の中央揃え */
}

/* グリッド外側 */
.b-grid-outer {
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}

.b-radius {
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px 30px 40px 30px !important;
}

.b-radius-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.b-radius-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

/* エリアごとの白背景色のスタイル */
.b-detail-items {
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px 0px 10px 0px !important;
}

/* コメントタイトルの枠のスタイル */
.b-input-text-square {
  border-radius: 0px 0px 5px 5px !important;
}
/* コメント欄の高さ */
.b-input-comment-height {
  height: 100px;
}

/* 基本ボタン */
.b-btn-primary {
  color: white;
  background-color: #fd6b00;
  border-color: #fd6b00;
  border-radius: 50rem;
  min-width: 120px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.b-btn-primary:disabled {
  color: white;
  background-color: #fd6b00;
  border-color: #fd6b00;
  border-radius: 50rem;
  min-width: 120px;
  opacity: 0.6;
}

/* Close、またはステータスを進めないボタン */
.b-btn-close,
.b-btn-light {
  color: black;
  background-color: #ffffff;
  border: solid 2px;
  border-color: #bebebe;
  border-radius: 50rem;
  min-width: 120px;
}

/* 削除ボタン */
.b-btn-delete {
  color: white;
  background-color: #6c757d;
  border-color: #6c757d;
  border-radius: 50rem;
  min-width: 120px;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* 廃止予定 */
.b-btn-secondary {
  color: white;
  background-color: #b0adad;
  border-color: #b0adad;
  border-radius: 50rem;
  min-width: 120px;
}

/* メイン項目タイトルのスタイル */
.b-input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  white-space: pre-line;
  background-color: #051869;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  color: white;
}

/* BEMAC入力項目タイトルのスタイル */
.b-input-group-text-light_blue {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  white-space: pre-line;
  background-color: #495d9e;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  color: white;
}

.b-input-group-text-orange {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e76300;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  color: white;
}

.b-input-group-value-text-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ページヘッダのドロップダウンの▼を非表示にする */
.navbar-nav a.dropdown-toggle.nav-link::after {
  display: none;
}
/* ナビ部の文字色 */
.nav-item a.nav-link {
  color: white;
}
.nav-item a.dropdown-toggle {
  color: white;
}

/* トグルスイッチ */
.form-check-input:checked {
  background-color: #fd6b00;
  border-color: #f8cbad;
}
.form-check-input:checked:disabled {
  background-color: #f8cbad;
  border-color: #f8cbad;
  opacity: 1;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='gray'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='pink'/%3e%3c/svg%3e");
}

/* トグルスイッチ青 */
.form-check-input-blue:checked {
  background-color: blue !important;
  border-color: #ccccfe !important;
}
.form-check-input-blue:checked:disabled {
  background-color: #ccccfe !important;
  border-color: #ccccfe !important;
  opacity: 1;
}

/* 通知バッヂ */
.badge {
  letter-spacing: 0.09rem;
}

/* パスワード表示アイコンを非表示に */
::-ms-reveal {
  display: none;
}

.hover-pointer:hover {
  cursor: pointer;
}

/* Modalの背景色 */
.modal-header {
  color: white;
  background-color: #051869;
}
.modal-body {
  background-color: #e4e4e4;
}
.modal-footer {
  background-color: #e4e4e4;
}

/* Modalヘッダーのタイトルと×ボタン色を白に */
.modal-header .btn-close {
  filter: invert(1);
  opacity: 1;
}

/* Modalヘッダーのタイトルと×ボタン色=白のhover時 */
.modal-header .btn-close:hover {
  opacity: 0.6;
}

/* ag-gridの外側を丸くする */
.ag-root-wrapper {
  border-radius: 10px;
}

/* テキストリンクの色 */
.text-link {
  color: #3778f4;
}

/* テキストリンクhover時 */
.text-link:hover {
  opacity: 0.6;
}

/* autocomplete後のテキストボックス活性時 */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset;
  -webkit-text-fill-color: black;
}

/* autocomplete後のテキストボックス非活性時 */
input:-webkit-autofill:disabled {
  border: solid;
  border-width: 1px;
  border-color: #595959;
  -webkit-box-shadow: 0 0 0 1000px #b7bdc7 inset;
  -webkit-text-fill-color: black;
}

/* スクロールバー全体 */
::-webkit-scrollbar {
  height: 21px;
  width: 21px;
}

/* 縦スクロールバー背景 */
::-webkit-scrollbar-track:vertical {
  margin-top: 1vh;
  margin-bottom: 1.5vh;
}

/* 縦スクロールバー本体 */
::-webkit-scrollbar-thumb:vertical {
  border-radius: 10px;
  background: #b5b5b5 0% 0% no-repeat padding-box;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

/* 横スクロールバー背景 */
::-webkit-scrollbar-track:horizontal {
  margin-left: 1vh;
  margin-right: 1.5vh;
}

/* 横スクロールバー本体 */
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background: #b5b5b5 0% 0% no-repeat padding-box;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

/* 縦横スクロールバーの交点 */
::-webkit-scrollbar-corner {
  background: transparent;
}

/* ページタイトルスタイル */
.title-area-style {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0px 30px 30px;
}

/* サブタイトルスタイル */
.sub-title-font-style {
  font-size: 17.5px;
  font-weight: bold;
}

.ag-header-group-cell-label .ag-header-group-text,
.ag-header-cell-label {
  white-space: normal;
  line-height: 1.2;
  word-wrap: break-word;
}
