.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid gray;
  margin: 0px;
  padding: 0;
}

.react-tabs__tab {
  display: table-cell;
  background: #a9a9b1;
  border: 1px solid transparent;
  border-color: gray;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  margin-right: 4px;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  width: 194px;
  height: 48px;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.react-tabs__tab--selected {
  background: white;
  border-color: #aaa;
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
